<template>
	<section class="container">
		<div class="overview-container">
			<div class="card">
				<h1 class="section-title"><b> Løn</b></h1>
				<div class="section-content">
					<p><strong>Månedsløn:</strong> {{ numberFormat(employeePolicy.employeeCondition.salary) }} kr.</p>
					<p><strong>Tillægsløn:</strong> {{ numberFormat(employeePolicy.employeeCondition.supplementarySalary) }} kr.</p>
				</div>
			</div>
			<div class="card">
				<h1 class="section-title"><b> Rådgiver </b></h1>
				<div class="section-content">
					<p><strong>Navn:</strong> {{ getPrimaryAdvisor().fullName }}</p>
					<p><strong>Email:</strong> {{ getPrimaryAdvisor().email }}</p>
				</div>
			</div>
		</div>
		<br />
		<div>
			<div class="card">
				<h1 class="section-title"><b> Pension </b></h1>
				<div class="section-content">
					<BaseTable :items="getPensions" :fields="pensionFields" :isBusy="isBusy">
						<template v-slot:cell(supplier)="data">
							{{ getSupplier(data.item.supplier).name }}
						</template>
						<template v-slot:cell(title)="data">
							{{ data.item.title }}
						</template>
						<template v-slot:cell(tax)="data">
							<template v-if="!data.item.tax"> - </template>
							<template v-else>
								{{ taxFormat(data.item.tax) }}
							</template>
						</template>
						<template v-slot:cell(value)="data">
							<template v-if="data.item.valuePercentage"> {{ numberFormat(data.item.valuePercentage) }}% </template>
						</template>
						<template v-slot:cell(valueMonth)="data"> {{ numberFormatRound(data.item.valueMonth) }} kr. </template>
						<template v-slot:cell(valueYear)="data"> {{ numberFormatRound(data.item.valueYear) }} kr. </template>
					</BaseTable>
				</div>
			</div>
			<br />
			<div class="card">
				<h1 class="section-title"><b> Død </b></h1>
				<div class="section-content">
					<BaseTable :items="getDeathInsurance" :fields="deathFields" :isBusy="isBusy">
						<template v-slot:cell(supplier)="data">
							{{ getSupplier(data.item.supplier).name }}
						</template>
						<template v-slot:cell(title)="data">
							{{ data.item.title }}
						</template>
						<template v-slot:cell(tax)="data">
							<template v-if="!data.item.tax"> - </template>
							<template v-else>
								{{ taxFormat(data.item.tax) }}
							</template>
						</template>
						<template v-slot:cell(valueAnnualPayment)="data">
							<template v-if="data.item.valueAnnualPayment"> {{ numberFormatRound(data.item.valueAnnualPayment) }} kr. </template>
						</template>
						<template v-slot:cell(valueAnnualPercentage)="data">
							<template v-if="data.item.valueAnnualPercentage"> {{ numberFormat(data.item.valueAnnualPercentage) }}% </template>
						</template>
						<template v-slot:cell(valueOneTimePayment)="data">
							<template v-if="data.item.valueOneTimePayment"> {{ numberFormatRound(data.item.valueOneTimePayment) }} kr. </template>
						</template>
					</BaseTable>
				</div>
			</div>
			<br />

			<div class="card">
				<h1 class="section-title"><b> Sygdom </b></h1>
				<div class="section-content">
					<BaseTable :items="getDiseaseInsurance" :fields="diseaseFields" :isBusy="isBusy">
						<template v-slot:cell(supplier)="data">
							{{ getSupplier(data.item.supplier).name }}
						</template>
						<template v-slot:cell(title)="data">
							{{ data.item.title }}
						</template>
						<template v-slot:cell(tax)="data">
							<template v-if="!data.item.tax"> - </template>
							<template v-else>
								{{ taxFormat(data.item.tax) }}
							</template>
						</template>
						<template v-slot:cell(valueAnnualPayment)="data">
							<template v-if="data.item.valueAnnualPayment"> {{ numberFormatRound(data.item.valueAnnualPayment) }} kr. </template>
						</template>
						<template v-slot:cell(valueAnnualPercentage)="data">
							<template v-if="data.item.valueAnnualPercentage == 'Lønskala'">
								{{ data.item.valueAnnualPercentage }}
							</template>
							<template v-else-if="data.item.valueAnnualPercentage"> {{ numberFormat(data.item.valueAnnualPercentage) }}% </template>
						</template>
						<template v-slot:cell(valueOneTimePayment)="data">
							<template v-if="data.item.valueOneTimePayment"> {{ numberFormatRound(data.item.valueOneTimePayment) }} kr. </template>
						</template>
					</BaseTable>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { CoverageOverview } from '@/models/sub-models/general/coverageOverview'
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'

export default {
	name: 'EmployeeCoverageOverview',
	components: { BaseTable },
	async mounted() {
		this.$parent.$emit('updateLoader')
		await this.$store.dispatch('companyVuex/getCompany', this.companyId)
		await this.$store.dispatch('employeeVuex/getUser', this.employeeId)
		await this.$store.dispatch('employeeVuex/getEmployeePolicy', {
			employeeId: this.employeeId,
			companyId: this.companyId,
			policyId: this.policyId,
		})
		this.setCoverageModel()
		this.isBusy = false
	},
	data() {
		return {
			isBusy: true,
			coverageOverview: new CoverageOverview({}),
			diseaseFields: [
				{
					key: 'supplier',
					label: 'Leverandør',
					sortable: true,
				},
				{
					key: 'title',
					label: 'Bidragstype',
					sortable: true,
				},
				{
					key: 'coverageType',
					label: 'Dækningstype',
					sortable: true,
				},
				{
					key: 'comment',
					label: 'Kommentar',
					sortable: true,
				},
				{
					key: 'tax',
					label: 'Beskatning',
					sortable: true,
				},
				{
					key: 'valueAnnualPercentage',
					label: 'Udbetaling i % af årsløn',
					sortable: true,
				},
				{
					key: 'valueAnnualPayment',
					label: 'Løbende årlig udbetaling',
					sortable: true,
				},
				{
					key: 'valueOneTimePayment',
					label: 'Engangsudbetaling',
					sortable: true,
				},
			],
			deathFields: [
				{
					key: 'supplier',
					label: 'Leverandør',
					sortable: true,
				},
				{
					key: 'title',
					label: 'Bidragstype',
					sortable: true,
				},
				{
					key: 'coverageType',
					label: 'Dækningstype',
					sortable: true,
				},
				{
					key: 'comment',
					label: 'Kommentar',
					sortable: true,
				},
				{
					key: 'tax',
					label: 'Beskatning',
					sortable: true,
				},
				{
					key: 'valueAnnualPercentage',
					label: 'Udbetaling i % af årsløn',
					sortable: true,
				},
				{
					key: 'valueAnnualPayment',
					label: 'Løbende årlig udbetaling',
					sortable: true,
				},
				{
					key: 'valueOneTimePayment',
					label: 'Engangsudbetaling',
					sortable: true,
				},
			],
			pensionFields: [
				{
					key: 'supplier',
					label: 'Leverandør',
					sortable: true,
				},
				{
					key: 'title',
					label: 'Aftaletype',
					sortable: true,
				},
				{
					key: 'comment',
					label: 'Kommentar',
					sortable: true,
				},
				{
					key: 'tax',
					label: 'Skattekode',
					sortable: true,
				},
				{
					key: 'value',
					label: '%',
					sortable: true,
				},
				{
					key: 'valueMonth',
					label: 'Måned',
					sortable: true,
				},
				{
					key: 'valueYear',
					label: 'År',
					sortable: true,
				},
			],
			clicked: false,
			disabled: true,
		}
	},
	methods: {
		getPrimaryAdvisor() {
			var advisors = this.$store.getters['employeeVuex/companyAdvisors']
			return advisors?.[0] ?? { fullName: '-', email: '-' }
		},
		numberFormat(number) {
			return Number(number).toLocaleString('da-DK')
		},
		numberFormatRound(number) {
			return Math.round(Number(number)).toLocaleString('da-DK')
		},
		taxFormat(tax) {
			switch (tax) {
				case 'SK5':
				case 'SK7':
				case 'SK33':
					return tax + ' ' + i18n.t('global.taxFree')
				case 'SK1':
				case 'SK2':
				case 'SK8':
					return tax + ' ' + i18n.t('global.incomeTax')
				case 'SK3':
					return tax + ' ' + i18n.t('global.taxable')
				default:
					return tax
			}
		},
		getSupplier(id) {
			return (
				this.suppliers.find((item) => {
					return item.id == id
				}) ?? { name: '??' }
			)
		},
		setCoverageModel() {
			try {
				var employeePolicy = this.employeePolicy
				var company = this.company
				var coverageOverview = new CoverageOverview({
					employeePolicy: employeePolicy,
					company: company,
				})
				this.coverageOverview = coverageOverview
			} catch (e) {
				console.log(e)
				this.toast('FEJL', 'Medarbejderens pension eller forsikring er ikke sat korrekt op', false)
			}
		},
	},
	computed: {
		companyInsurances() {
			return this.company?.insurances
		},
		companyPensions() {
			return this.company?.pensions
		},
		getDiseaseInsurance() {
			var result = this.coverageOverview.getDiseaseInsurance()
			return result
		},
		getDeathInsurance() {
			var result = this.coverageOverview.getDeathInsurance()
			return result
		},
		getPensions() {
			var result = this.coverageOverview.getPensions()
			return result
		},
		advisors() {
			return this.$store.getters['user/users']
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
		companyId() {
			return this.$route.params.companyId
		},
		employeeId() {
			return this.$route.params.employeeId
		},
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		employeeInfo() {
			return this.$store.getters['employeeVuex/user']
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
		policyId() {
			return this.$route.params.policyId
		},
	},
}
</script>

<style scoped>
.overview-container {
	display: flex;
	flex-direction: row; /* Change to row to align items horizontally */
	gap: 20px;
	flex-wrap: wrap; /* Allows items to wrap to the next line if the container width is not enough */
}

.card {
	background-color: #f9f9f9;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	flex: 1; /* Allows the card to grow and fill the space, adjust as needed */
	min-width: 300px; /* Minimum width before wrapping, adjust based on content */
}

.section-title {
	font-size: 24px;
	color: #333;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	gap: 10px;
}

.section-content p {
	font-size: 16px;
	margin: 5px 0;
}

.fas {
	color: #4a90e2;
}
</style>
